.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.openSider .sticky-header {
  width: 100% !important;
}

.openSiderMenu .sticky-header-sidebar {
  width: 100% !important;
}

.content-wrap {
  position: relative;
  transition: ease-in-out 0.5s;
  float: left;
  width: 100%;
}

.content-wrap-sidebar {
  position: relative;
  transition: ease-in-out 0.5s;
  float: left;
  width: 100%;
}

.openSider .content-wrap {
  padding: 0px;
}

.openSiderMenu .content-wrap-sidebar {
  padding: 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
}

.modal_wrapper .ant-modal-content {
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background: #ffffff;
}

.modal_wrapper .ant-checkbox-inner {
  border-radius: 5px;
}

.modal_wrapper .ant-modal-close {
  top: 14px;
  right: 14px;
}

.modal_wrapper .ant-modal-body {
  padding: 40px 50px !important;
}

.modal_close_icon {
  font-size: 50px;
  line-height: 34px;
  color: #2b353f;
}

.modal_close_icon_header {
  font-size: 50px;
  line-height: 34px;
  color: #fff;
  position: absolute;
  right: 0;
}

.gm-style .gm-style-iw-c {
  max-width: 335px !important;
  padding: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 5px !important;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-ui-hover-effect {
  opacity: 0.6;
  width: 40px !important;
  height: 40px !important;
}

.gm-ui-hover-effect>span {
  background-color: #000;
  font-size: 72px;
  width: 26px !important;
  height: 25px !important;
}

.openSider .left-panel {
  /* left: -280px; */
  transform: translateY(130%);
  box-shadow: none;
}

.openSiderMenu .sidebar-panel {
  right: -280px;
  transform: translateX(130%);
  box-shadow: none;
}

@media (max-width: 1550px) {
  .modal_wrapper .ant-modal-body {
    padding: 30px !important;
  }
}

@media (max-width: 991.98px) {
  .content-wrap {
    padding: 0;
  }

  .content-wrap-sidebar {
    padding: 0;
  }

  .openSider .left-panel {
    /* left: 0; */
    box-shadow: none;
  }


  .openSiderMenu .sidebar-panel {
    /* left: 0; */
    box-shadow: none;
  }

  .openSider .back-drop {
    display: none;
  }

  .openSiderMenu .back-drop-sidebar {
    display: none;
  }

  .back-drop {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
    min-height: 100vh;
  }

  .back-drop-sidebar {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 90px;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    min-height: 100vh;
  }
}

@media (max-width: 575.98px) {
  .modal_wrapper .ant-modal-body {
    padding: 25px 30px !important;
  }
}

@media (max-width: 425.98px) {
  .ant-modal-body {
    padding: 15px;
  }
}

@media (max-width: 400px) {
  .modal_wrapper .ant-modal-body {
    padding: 15px 25px !important;
  }

  .modal_wrapper .ant-modal-close {
    top: 0;
    right: 0;
  }

}