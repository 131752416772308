.ant-select-item-option-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2B353F;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #ECF2FF;
}

.ant-select-item-option {
    height: 40px;
    align-items: center;
}

.ant-select-selector {
    border-radius: 50px;
}